import { AxiosPromise } from 'axios';
import api, { pathAPIMNavProApi } from '../axios/api';

const basePath = `${pathAPIMNavProApi}/servicos/auth`;

export interface ILoginPayload {
  idp: string;
  username: string;
  password: string;
}

export interface ILoginResponse {
  orchestrator_access_token: string;
  orchestrator_refresh_token: string;
  expires_in: number;
  refresh_expires_in: number;
}

export interface ILoginLegacy extends ILoginResponse {
  access_token: string;
  refresh_token: string;
  access_token_pes: string;
  refresh_token_pes: string;
  expires_in_pes: number;
  refresh_expires_in_pes: number;
}

export interface ILoginTempResponse extends ILoginResponse {
  data: ILoginLegacy;
}

export interface IRefreshTempResponse {
  data: ILoginLegacy;
}

export interface ILoginErrorResponse {
  http_status: number;
  message: string;
  service_code: string;
}

const { REACT_APP_APIM_NAV_PRO_SUBSCRIPTION_KEY } = process.env;
const headers = {
  'subscription-key': REACT_APP_APIM_NAV_PRO_SUBSCRIPTION_KEY,
};

const APIMAuthServices = {
  loginTemp: (payload: ILoginPayload): AxiosPromise<ILoginTempResponse> => {
    return api.post(`${basePath}/login/temp`, payload, { headers });
  },
  refreshTemp: (refreshToken: string): AxiosPromise<IRefreshTempResponse> => {
    return api.get(`${basePath}/refresh/temp`, { 
      headers: { 
        ...headers,
        Authorization: `Bearer ${refreshToken}`,
      }
    });
  },
};

export default APIMAuthServices;
