import { AxiosPromise } from 'axios';
import { AppConfigurationClient } from '@azure/app-configuration';
import environment from '~settings/environment';
import api, { pathTelemedicineDasa, pathPesTelemedicine } from './axios/api';
import { IFlagsPayload } from '~store/feature-flag/types';
import { IFeatureFlagListsParams } from '~/store/featureFlagLists/types';

const acsUrl = process.env.REACT_APP_AZURE_CONNECTION_STRING_URL || '';
const acsId = process.env.REACT_APP_AZURE_CONNECTION_STRING_ID || '';
const acsSecret = process.env.REACT_APP_AZURE_CONNECTION_STRING_SECRET || '';
const azureConnectionString = `Endpoint=${acsUrl};Id=${acsId};Secret=${acsSecret}`;

const FeatureFlagsServices = {
  fetchFeatureFlags: async (): Promise<IFlagsPayload> => {
    let flags: Partial<IFlagsPayload> = {};
    const client = new AppConfigurationClient(azureConnectionString);
    const result = await client.listConfigurationSettings({
      keyFilter: '.appconfig.featureflag*',
    });
    for await (const setting of result) {
      if (setting.value) {
        const getFlag = JSON.parse(setting.value);
        const newFlag = { [getFlag.id]: getFlag.enabled };
        flags = { ...flags, ...newFlag };
      }
    }
    return flags;
  },
  loadFeatureFlags: (): AxiosPromise =>
    api.get(
      `${pathPesTelemedicine}/feature-flags/${environment.featureFlag.environment}`,
    ),
  getFeatureFlagLists: (params: IFeatureFlagListsParams): AxiosPromise =>
    api.get(
      `${pathTelemedicineDasa}/feature-flags/list/${params.listName}/value/${params.value}`,
    ),
  getFeatureFlags: (): AxiosPromise =>
    api.get(`${pathTelemedicineDasa}/feature-flags`),
};

export default FeatureFlagsServices;
